import React, { useState } from 'react';
import axios from 'axios';
import { Button, Checkbox, Form, Input } from 'antd';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleLogin = async () => {

        try {
            setLoading(true);
            setError(null);

            // Send login request to the API endpoint
            const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/api/users/login`, {
                username,
                password,
            }).then((res) => {
                localStorage.setItem('_ims', res.data.token);
                window.location.reload()
            });

            // Handle successful login
            console.log('Login successful:', response.data);

            // Reset form fields
            setUsername('');
            setPassword('');
        } catch (error) {
            // Handle login error
            console.error('Login failed:', error);
            setError('Invalid username or password');
        } finally {
            setLoading(false);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.error('Login form validation failed:', errorInfo);
    };

    return (
        <div className="container h-[100vh] flex flex-col items-center justify-center mx-auto p-4">
            <div className="max-w-md border min-w-[300px] w-1/3 my-auto mx-auto bg-white rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Login</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <Form
                    name="login-form"
                    onFinish={handleLogin}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[{ required: true, message: 'Please enter your username' }]}
                    >
                        <Input placeholder="Enter your username" onChange={e => setUsername(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please enter your password' }]}
                    >
                        <Input.Password placeholder="Enter your password" onChange={e => setPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item className='w-full'>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" className='bg-black w-full' htmlType="submit" loading={loading}>
                            {loading ? 'Loading...' : 'Login'}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default LoginPage;
