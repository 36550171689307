import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React, { useRef, useState } from "react";

export default function SiteLogin() {
    const [loading, setLoading] = useState(false);
    const passwordRef = useRef(null)
    async function siteLogin() {
        setLoading(true);
        const userEnteredPassword = passwordRef.current.input.value;
        await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/match-password`, { userEnteredPassword }).then((res) => {
            if (res.data.verified) {
                localStorage.setItem("_issp", userEnteredPassword)
                window.location.reload()
            }
            else {
                message.error("incorrect password")
                setLoading(false)
            }
        })
    }

    return (
        <div className="w-1/3 p-5 min-w-[300px] shadow mx-auto my-[36px]">
            <div className="font-bold text-[20px]">Enter password </div>
            <Form onFinish={() => siteLogin()}>
                <div className="my-5">
                    <Input.Password ref={passwordRef} className="max-w-[300px] mx-auto" required />
                </div>
                <Button loading={loading} type="primary" htmlType="submit" ghost>Login</Button>
            </Form>

        </div>
    );
}
