import logo from './logo.svg';
import './App.css';
import ProductList from './pages/ProductList';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import Dashboard from './pages/Dashboard';
import CheckOutPage from './pages/CheckOutPage';
import SiteLogin from './pages/SiteLogin';

function App() {
  const [isLoading, setLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [siteIsLoggedIn, setSiteIsLoggedIn] = useState()

  useEffect(() => {
    const checkLoggedIn = async () => {
      const token = localStorage.getItem('_ims');
      if (token) {
        try {
          // Send request to fetch user data using the token
          const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/api/users/me`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          // Update the state with user data
          setUserData(response.data);
          setLoggedIn(true);
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      }

      setLoading(false);
    };

    checkLoggedIn();
  }, []);

  const checkSiteLogin = async (userEnteredPassword) => {
    const token = localStorage.getItem('_ims');

    if (userEnteredPassword) {
      await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/match-password`, { userEnteredPassword }, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        setSiteIsLoggedIn(res.data.verified)
      }).catch((err) => {
        console.log(err);
      })
    }
    else {
      setSiteIsLoggedIn(false);
    }
  }

  useEffect(() => {
    const savedPassword = localStorage.getItem("_issp")
    checkSiteLogin(savedPassword);
  })


  if (isLoading) {
    return (
      <div className='w-full h-[100vh] flex flex-col justify-center items-center'>
        <Spin size="small" />
      </div>
    );
  }
  return (
    <div className="App">

      <Routes>
        <Route path="/billing-details" element={<CheckOutPage />} />
        {
          !isLoggedIn ? (
            <>
              <Route path="/manage-inventory" element={<Login />} />
              <Route path="*" element={<Navigate to="/manage-inventory" />} />
            </>
          ) : (
            <>
              <Route path="/dashboard" element={<Dashboard user={userData} />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </>
          )
        }
        <Route path="/" element={siteIsLoggedIn ? <ProductList /> : <SiteLogin />} />

      </Routes>
    </div>
  );
}

export default App;
