import React, { useState, useEffect, useMemo } from 'react';
import { Table, Select, Slider, Modal, Form, Input, InputNumber, Button, Upload, message, Spin } from 'antd';
import { FileExcelOutlined, InboxOutlined, RedoOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { generateExcel } from '../helpers';

const { Option } = Select;
const { Dragger } = Upload;

const Dashboard = ({ user }) => {
    const [gradeFilter, setGradeFilter] = useState('');
    const [capacityFilter, setCapacityFilter] = useState('');
    const [priceRangeFilter, setPriceRangeFilter] = useState([0, 100]);
    const [modelFilter, setModelFilter] = useState([]);
    const [filteredData, setFilteredData] = useState(null);
    const [editingProduct, setEditingProduct] = useState(null);
    const [password, setPassword] = useState("");
    const [editForm] = Form.useForm();
    const [products, setProducts] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [priceIncrease, setPriceIncrease] = useState(0);

    useEffect(() => {
        getProducts();
    }, []);

    function getProducts() {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_ENDPOINT_URL}/api/products`)
            .then((res) => {
                setProducts(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }

    const grades = useMemo(() => [...new Set(products.map((product) => product.grade))], [products]);
    const capacities = useMemo(() => [...new Set(products.map((product) => product.capacity))], [products]);
    const models = useMemo(() => [...new Set(products.map((product) => product.model))], [products]);
    const prices = useMemo(() => products.map((product) => product.listPrice), [products]);

    const applyFilters = () => {
        let filteredData = products;

        if (gradeFilter) {
            filteredData = filteredData.filter((product) => product.grade === gradeFilter);
        }

        if (capacityFilter) {
            filteredData = filteredData.filter((product) => product.capacity === capacityFilter);
        }

        filteredData = filteredData.filter(
            (product) => product.listPrice >= priceRangeFilter[0] && product.listPrice <= priceRangeFilter[1]
        );

        if (modelFilter.length > 0) {
            filteredData = filteredData.filter((product) => modelFilter.includes(product.model));
        }

        setFilteredData(filteredData);
    };

    const debouncedApplyFilters = useMemo(() => debounce(applyFilters, 300), [
        gradeFilter,
        capacityFilter,
        priceRangeFilter,
        modelFilter,
    ]);

    useEffect(() => {
        debouncedApplyFilters();
        return debouncedApplyFilters.cancel;
    }, [debouncedApplyFilters]);

    const handleGradeFilter = (value) => {
        setGradeFilter(value);
    };

    const handleCapacityFilter = (value) => {
        setCapacityFilter(value);
    };

    const handlePriceRangeFilter = (value) => {
        setPriceRangeFilter(value);
    };

    const handleModelFilter = (value) => {
        setModelFilter(value);
    };

    const handleEdit = (product) => {
        setEditingProduct(product);
        editForm.setFieldsValue({
            name: product.model,
            price: product.listPrice,
            quantity: product.availableQuantity,
        });
    };

    const handleSave = () => {
        editForm.validateFields().then((values) => {
            const updatedProduct = {
                ...editingProduct,
                model: values.name,
                listPrice: values.price,
                availableQuantity: values.quantity,
            };
            axios
                .put(`${process.env.REACT_APP_ENDPOINT_URL}/api/products/${editingProduct._id}`, updatedProduct)
                .then(() => {
                    getProducts();
                })
                .catch((error) => {
                    console.error(error);
                });
            setEditingProduct(null);
            editForm.resetFields();
        });
    };

    const handleCancel = () => {
        setEditingProduct(null);
        editForm.resetFields();
    };

    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: '.xlsx, .xls',
        fileList,
        beforeUpload: (file) => {
            const isExcelFile =
                file.type === 'application/vnd.ms-excel' ||
                file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isExcelFile) {
                message.error('Only Excel files are supported!');
            } else {
                setFileList([file]);
            }
            return false; // Prevent default upload behavior
        },
        onRemove: () => {
            setFileList([]);
        },
    };

    const handleSubmitFile = () => {
        if (fileList.length === 0) {
            message.error('Please upload an Excel file!');
            return;
        }
        const file = fileList[0];

        const formData = new FormData();
        formData.append('file', file);
        formData.append('priceIncrease', priceIncrease);

        axios
            .post(`${process.env.REACT_APP_ENDPOINT_URL}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('_ims')}`,
                },
            })
            .then(() => {
                getProducts();
                message.success('Stock list updated');
                setFileList([]);
                setPriceIncrease(0);
            })
            .catch((error) => {
                message.error('File upload failed!');
                console.error(error);
            });
    };
    const generateSitePassword = async () => {
        await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/generate-password`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('_ims')}`,
            },
        }).then((res) => {
            setPassword(res.data.sitePassword)
        })
    }
    const columns = [
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Capacity',
            dataIndex: 'capacity',
            key: 'capacity',
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            key: 'grade',
        },
        {
            title: 'Quantity',
            dataIndex: 'availableQuantity',
            key: 'quantity',
        },
        {
            title: 'Price (USD)',
            dataIndex: 'listPrice',
            key: 'price',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
                <Button type="link" onClick={() => handleEdit(record)}>
                    Edit
                </Button>
            ),
        },
    ];

    return (
        <div className="container mx-auto p-4">
            <div className="text-center flex flex-col items-center my-[50px] w-full">
                <h1 className="text-[24px] font-bold">Stock List</h1>
                <p className="opacity-70 mt-4">Last updated: {moment(products[0]?.dateUpdated).fromNow()}</p>
                <div className="text-white bg-blue-600 mt-4 px-2 max-w-[200px] w-auto rounded-md flex">
                    Logged in as {user.username}
                </div>
                <small className='mt-5'><i>{password ? ("Password: " + password) : " Password: click on generate to create new"}</i></small>
                <Button type="default" className='mt-5' onClick={() => generateExcel(products)} icon={<FileExcelOutlined />}>Export Inventory</Button>
                <Button type="default" className='mt-5' onClick={() => generateSitePassword()} icon={<RedoOutlined />}>Generate New Password</Button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div className="md:col-span-1">
                    <div className="bg-white shadow-md lg:min-h-[500px] p-4 rounded-md">
                        <h2 className="text-lg font-semibold mb-4">Filters</h2>
                        <div className="mb-4 border-t pt-5">
                            <label htmlFor="gradeFilter" className="block font-medium mb-1">
                                Grade
                            </label>
                            <Select
                                id="gradeFilter"
                                placeholder="Select Grade"
                                value={gradeFilter}
                                onChange={handleGradeFilter}
                                style={{ width: '100%' }}
                            >
                                <Option value="">All</Option>
                                {grades.map((grade) => (
                                    <Option key={grade} value={grade}>
                                        {grade}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="capacityFilter" className="block font-medium mb-1">
                                Capacity
                            </label>
                            <Select
                                id="capacityFilter"
                                placeholder="Select Capacity"
                                value={capacityFilter}
                                onChange={handleCapacityFilter}
                                style={{ width: '100%' }}
                            >
                                <Option value="">All</Option>
                                {capacities.map((capacity) => (
                                    <Option key={capacity} value={capacity}>
                                        {capacity}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="modelFilter" className="block font-medium mb-1">
                                Model
                            </label>
                            <Select
                                id="modelFilter"
                                placeholder="Select Model"
                                value={modelFilter}
                                onChange={handleModelFilter}
                                mode="multiple"
                                style={{ width: '100%' }}
                            >
                                {models.map((model) => (
                                    <Option key={model} value={model}>
                                        {model}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <label className="block font-medium mb-1">Price Range</label>
                            <Slider
                                range
                                onChange={handlePriceRangeFilter}
                                value={priceRangeFilter}
                                min={Math.min(...prices)}
                                max={Math.max(...prices)}
                            />
                        </div>

                        <div className="mt-4">
                            <label className="block font-medium mb-1">Import List</label>
                            <Form.Item
                                label="Price Increase (%)"
                                name="priceIncrease"
                                rules={[{ required: true, message: 'Please enter the price increase' }]}
                            >
                                <InputNumber min={0} onChange={setPriceIncrease} />
                            </Form.Item>

                            <Dragger {...uploadProps}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">Only Excel files are supported</p>
                            </Dragger>
                        </div>
                        <div className="mt-4">
                            <Button
                                type="primary"
                                className="bg-black"
                                onClick={handleSubmitFile}
                                disabled={fileList.length === 0}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="md:col-span-4">
                    {loading ? (
                        <Spin />
                    ) : (
                        <Table
                            dataSource={filteredData?.length > 0 ? filteredData : products}
                            pagination={{ defaultPageSize: 100, showSizeChanger: true }}
                            columns={columns}
                        />
                    )}
                </div>
            </div>
            <Modal
                visible={!!editingProduct}
                title="Edit Product"
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" className="bg-black" onClick={handleSave}>
                        Save
                    </Button>,
                ]}
            >
                <Form form={editForm} layout="vertical">
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter the name' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Price"
                        name="price"
                        rules={[{ required: true, message: 'Please enter the price' }]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[{ required: true, message: 'Please enter the quantity' }]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Dashboard;
