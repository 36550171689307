import React, { useEffect, useState } from "react";

export default function CheckOutPage() {
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    const getSavedCartItems = () => {
        const sessionString = localStorage.getItem("cartItems");
        if (sessionString !== null) {
            setCartItems(JSON.parse(sessionString))
        }

    }
    useEffect(() => {
        const calculatedTotalPrice = cartItems.reduce((total, item) => total + item.totalPrice, 0);
        setTotalPrice(calculatedTotalPrice ? calculatedTotalPrice : 0);
    }, [cartItems]);
    useEffect(() => {
        getSavedCartItems()
    }, []);
    return (
        <div className="h-full flex justify-center w-full">
            <div className="card lg:w-1/3 min-w-[300px] my-5 border rounded-md p-4">
                <div className="mb-[50px]">
                    <p><u><b>Wire instructions</b></u></p>
                </div>
                <div className="w-full mb-[50px] text-start">
                    <p>
                        <b>Beneficiary</b><br /><br />
                        DM Wireless, LLC<br />
                        2450 S 4th AVE<br />
                        STE 306<br />
                        Yuma, AZ 85364
                    </p>
                </div>
                <div className="w-full text-start">
                    <p>
                        <b>Bank information </b><br /><br />
                        Foothills Bank<br />
                        11689 S Foothills BLVD<br />
                        Yuma, AZ 85368<br />
                        Account # <br />
                        Routing #<br />
                        Swift Code:
                    </p>
                </div>
                {cartItems.length > 0 ? (
                    <div>
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th>Model</th>
                                    <th>Capacity</th>
                                    <th>Grade</th>
                                    <th>Quantity</th>
                                    <th>Price (USD)</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartItems.map((item) => (
                                    <tr key={item.model}>
                                        <td>{item.model}</td>
                                        <td>{item.capacity}</td>
                                        <td>{item.grade}</td>
                                        <td>
                                            {item.quantity}
                                        </td>
                                        <td>{item.listPrice}</td>
                                        <td>{item.listPrice * item.quantity}</td>
                                    </tr>
                                ))}
                            </tbody><br />
                            <tfoot >
                                <tr>
                                    <td colSpan={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                        Total Price:
                                    </td>
                                    <td>{totalPrice}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                ) : (
                    <p>No items in the cart</p>
                )}
            </div>
        </div>
    );
}
