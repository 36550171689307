// TODO: Add verification that a valid quantity was added
// TODO: Add a remove from cart button
import React, { useState, useEffect, useMemo } from 'react';
import { Table, Select, Slider, Input, Button, Modal, InputNumber, message } from 'antd';
import axios from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const ProductList = () => {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [capacityFilter, setCapacityFilter] = useState('');
    const [priceRangeFilter, setPriceRangeFilter] = useState([0, 1000]);
    const [modelFilter, setModelFilter] = useState([]);
    const [lockStatusFilter, setLockStatusFilter] = useState('');
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [selectedMake, setSelectedMake] = useState([]);
    const [selectedCapacities, setSelectedCapacities] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddToCartModal, setShowAddToCartModal] = useState(false);
    const [showCartModal, setShowCartModal] = useState(false);
    const [selectedCartItem, setSelectedCartItem] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    // const [isCheckingOut,setIsCheckingOut] = useState(false)

    const grades = useMemo(() => [...new Set(products.map((product) => product.grade))], [products]);
    const capacities = useMemo(() => [...new Set(products.map((product) => product.capacity))], [products]);
    const models = useMemo(() => [...new Set(products.map((product) => product.model))], [products]);
    const makes = useMemo(() => [...new Set(products.map((product) => product.make))], [products]);
    const prices = useMemo(() => products.map((product) => product.listPrice), [products]);


    const addCartItemsToBrowser = (cartItems) => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems))
    }

    const getSavedCartItems = () => {
        const sessionString = localStorage.getItem("cartItems");
        if (sessionString !== null && sessionString !== "undefined") {
            setCartItems(JSON.parse(sessionString))
        }
    }



    const columns = [
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Capacity',
            dataIndex: 'capacity',
            key: 'capacity',
        },
        {
            title: 'Grade',
            dataIndex: 'grade',
            key: 'grade',
            render: (grade) => grade.split(" ")[0]
        },
        {
            title: 'Warehouse',
            dataIndex: 'warehouse',
            key: 'warehouse',
            render: (grade) => grade.split(" ")[1]
        },
        {
            title: 'Lock Status',
            dataIndex: 'lockStatus',
            key: 'lockStatus',
        },
        {
            title: 'Quantity',
            dataIndex: 'availableQuantity',
            key: 'quantity',
        },
        {
            title: 'Price (USD)',
            dataIndex: 'listPrice',
            key: 'price',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button
                    onClick={() => handleAddToCart(record)}
                    disabled={isItemInCart(record)}
                >
                    Add to Cart
                </Button>
            ),
        },
    ];



    const applyFilters = () => {
        const filteredData = products.filter(
            (product) =>
                (selectedGrades.length === 0 || selectedGrades.includes(product.grade)) &&
                (selectedMake.length === 0 || selectedMake.includes(product.make)) &&
                (selectedCapacities.length === 0 || selectedCapacities.includes(product.capacity)) &&
                (capacityFilter === '' || product.capacity === capacityFilter) &&
                product.listPrice >= priceRangeFilter[0] &&
                product.listPrice <= priceRangeFilter[1] &&
                (modelFilter.length === 0 || modelFilter.includes(product.model)) &&
                (lockStatusFilter === '' || product.lockStatus === lockStatusFilter) &&
                (searchTerm === '' || product.model.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredData(filteredData);
    };



    const debouncedApplyFilters = useMemo(() => debounce(applyFilters, 300), [
        selectedGrades,
        capacityFilter,
        priceRangeFilter,
        modelFilter,
        lockStatusFilter,
        searchTerm,
        selectedMake,
        selectedCapacities,
    ]);



    const handlePriceRangeFilter = (value) => {
        setPriceRangeFilter(value);
    };



    const handleModelFilter = (value) => {
        setModelFilter(value);
    };



    const handleLockStatusFilter = (value) => {
        setLockStatusFilter(value);
    };



    const handleMakeFilter = (value) => {
        setSelectedMake(value);
    };



    const handleCapacitiesFilter = (value) => {
        setSelectedCapacities(value);
    };



    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };



    const handleAddToCart = (item) => {
        setSelectedCartItem(item);
        setShowAddToCartModal(true);
    };



    const handleRemoveFromCart = (objectToRemove) => {
        setCartItems((cartItems) =>
            cartItems.filter((item) => item._id !== objectToRemove._id)
        );
        addCartItemsToBrowser((cartItems) =>
            cartItems.filter((item) => item._id !== objectToRemove._id)
        );
    };



    const handleAddToCartSubmit = () => {
        if (selectedCartItem.quantity === null || selectedCartItem.quantity === undefined) {
            message.error("Please enter a valid quantity")
            return;
        }
        const updatedCartItems = [...cartItems];
        // const existingCartItemIndex = cartItems.findIndex((item) => item._id === selectedCartItem._id);
            updatedCartItems.push(selectedCartItem);
        
        addCartItemsToBrowser(updatedCartItems);
        setCartItems(updatedCartItems);
        setShowAddToCartModal(false);
    };



    const handleQuantityChange = (value, item) => {
        const updatedCartItem = { ...item, quantity: value, totalPrice: item.listPrice * value };
        setSelectedCartItem(updatedCartItem);

        const updatedCartItems = cartItems.map((cartItem) =>
            cartItem._id === item._id ? updatedCartItem : cartItem
        );
        setCartItems(updatedCartItems);
    };



    const isItemInCart = (item) => {
        return cartItems.some((cartItem) => cartItem._id === item._id);
    };




    const handleViewCart = () => {
        setShowCartModal(true);
    };



    const handleCheckout = () => {
        // Perform checkout logic here
        setShowCartModal(false);
        navigate("/billing-details")
    };

    useEffect(() => {
        const calculatedTotalPrice = cartItems.reduce((total, item) => total + item.totalPrice, 0);
        setTotalPrice(calculatedTotalPrice ? calculatedTotalPrice : 0);
    }, [cartItems]);



    useEffect(() => {
        getSavedCartItems()
    }, []);



    useEffect(() => {
        debouncedApplyFilters();
        return debouncedApplyFilters.cancel;
    }, [debouncedApplyFilters]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/api/products`).then((res) => {
            const products = res.data.sort((a, b) => a.listPrice - b.listPrice);
            products.sort()
            setProducts(res.data);
        });
    }, []);


    return (
        <div className="container mx-auto p-4">
            <div className="text-center my-[50px] w-full">
                <img src="/dm-logo.png" alt="dm logo" className="mx-auto mb-5" width={120} />
                <h1 className="text-[24px] font-bold">Stock List</h1>
                <p className="opacity-70 mt-4">Last updated: {moment(products[0]?.dateUpdated).fromNow()}</p>
                <Button onClick={handleViewCart} className="mt-4">
                    View Cart
                </Button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div className="md:col-span-1">
                    <div className="bg-white shadow-md lg:min-h-[500px] p-4 rounded-md">
                        <h2 className="text-lg font-semibold mb-4">Filters</h2>
                        <div className="mb-4 border-t pt-5">
                            <label htmlFor="gradeFilter" className="block font-medium mb-1">
                                Grade
                            </label>
                            <Select
                                id="gradeFilter"
                                placeholder="Select Grades"
                                value={selectedGrades}
                                onChange={setSelectedGrades}
                                mode="multiple"
                                style={{ width: '100%' }}
                            >
                                {grades.map((grade) => (
                                    <Option key={grade} value={grade}>
                                        {grade}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="makeFilter" className="block font-medium mb-1">
                                Make
                            </label>
                            <Select
                                id="makeFilter"
                                placeholder="Select Make"
                                value={selectedMake}
                                onChange={handleMakeFilter}
                                mode="multiple"
                                style={{ width: '100%' }}
                            >
                                {makes.map((make) => (
                                    <Option key={make} value={make}>
                                        {make}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="capacityFilter" className="block font-medium mb-1">
                                Capacity
                            </label>
                            <Select
                                id="capacityFilter"
                                placeholder="Select Capacity"
                                value={selectedCapacities}
                                onChange={handleCapacitiesFilter}
                                mode="multiple"
                                style={{ width: '100%' }}
                            >
                                {capacities.map((capacity) => (
                                    <Option key={capacity} value={capacity}>
                                        {capacity}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="modelFilter" className="block font-medium mb-1">
                                Model
                            </label>
                            <Select
                                id="modelFilter"
                                placeholder="Select Model"
                                value={modelFilter}
                                onChange={handleModelFilter}
                                mode="multiple"
                                style={{ width: '100%' }}
                            >
                                {models.map((model) => (
                                    <Option key={model} value={model}>
                                        {model}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="lockStatusFilter" className="block font-medium mb-1">
                                Lock Status
                            </label>
                            <Select
                                id="lockStatusFilter"
                                placeholder="Select Lock Status"
                                value={lockStatusFilter}
                                onChange={handleLockStatusFilter}
                                style={{ width: '100%' }}
                            >
                                <Option value="">All</Option>
                                <Option value="LOCKED">Locked</Option>
                                <Option value="UNLOCKED">Unlocked</Option>
                            </Select>
                        </div>
                        <div>
                            <label className="block font-medium mb-1">Price Range</label>
                            <Slider
                                range
                                onChange={handlePriceRangeFilter}
                                value={priceRangeFilter}
                                min={Math.min(...prices)}
                                max={Math.max(...prices)}
                            />
                        </div>
                        <Input.Search
                            type="text"
                            placeholder="Search by name"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            style={{ width: 200, marginLeft: 8 }}
                        />
                    </div>
                </div>
                <div className="md:col-span-4">
                    <Table
                        dataSource={filteredData.length > 0 ? filteredData : products}
                        pagination={{ defaultPageSize: 100, showSizeChanger: true }}
                        columns={columns}
                    />
                </div>
            </div>
            <Modal
                visible={showAddToCartModal}
                title="Add to Cart"
                onCancel={() => setShowAddToCartModal(false)}
                footer={[
                    <Button key="cancel" onClick={() => setShowAddToCartModal(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="success" onClick={handleAddToCartSubmit}>
                        Add to Cart
                    </Button>,
                ]}
            >
                {selectedCartItem && (
                    <div>
                        <h3>{selectedCartItem.model}</h3>
                        <p>Price: {selectedCartItem.listPrice}</p>
                        <label>Quantity:</label>
                        <InputNumber
                            min={1}
                            defaultValue={1}
                            value={selectedCartItem.quantity}
                            onChange={(value) => handleQuantityChange(value, selectedCartItem)}
                        />
                        <p>Total Price: {selectedCartItem.quantity !== undefined ? (selectedCartItem.listPrice * selectedCartItem.quantity) : ((selectedCartItem.listPrice * 1))}</p>
                    </div>
                )}
            </Modal>
            <Modal
                visible={showCartModal}
                title="Cart"
                onCancel={() => setShowCartModal(false)}
                footer={[
                    <Button key="cancel" onClick={() => setShowCartModal(false)}>
                        Cancel
                    </Button>,
                    <Button key="checkout" type="success" onClick={handleCheckout}>
                        Checkout
                    </Button>,
                ]}
            >
                {cartItems.length > 0 ? (
                    <div>
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th>Model</th>
                                    <th>Capacity</th>
                                    <th>Grade</th>
                                    <th>Quantity</th>
                                    <th>Price (USD)</th>
                                    <th>Total Price</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartItems.map((item) => (
                                    <tr key={item.model}>
                                        <td>{item.model}</td>
                                        <td>{item.capacity}</td>
                                        <td>{item.grade}</td>
                                        <td>
                                            <InputNumber
                                                min={1}
                                                defaultValue={item.quantity}
                                                value={item.quantity}
                                                onChange={(value) => handleQuantityChange(value, item)}
                                            />
                                        </td>
                                        <td>{item.listPrice}</td>
                                        <td>{item.listPrice * item.quantity}</td>
                                        <td><Button danger type="text" onClick={() => handleRemoveFromCart(item)}>Remove</Button></td>
                                    </tr>
                                ))}
                            </tbody><br />
                            <tfoot >
                                <tr>
                                    <td colSpan={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                        Total Price:
                                    </td>
                                    <td>{totalPrice}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                ) : (
                    <p>No items in the cart</p>
                )}
            </Modal>
        </div>
    );
};

export default ProductList;
