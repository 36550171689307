import * as XLSX from 'xlsx';

export const generateExcel = (jsonData) => {
    // Transform the JSON data to match the desired column names
    const formattedData = jsonData.map((item) => ({
        model: item.model,
        capacity: item.capacity,
        grade: item.grade,
        'lock status': item.lockStatus,
        quantity: item.availableQuantity,
        price: item.listPrice,
    }));

    // Create a workbook with a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Set column headers
    worksheet['A1'].v = 'Model';
    worksheet['B1'].v = 'Capacity';
    worksheet['C1'].v = 'Grade';
    worksheet['D1'].v = 'Lock Status';
    worksheet['E1'].v = 'Quantity';
    worksheet['F1'].v = 'Price';

    // Set column widths
    const columnWidths = [
        { wch: 20 }, // Model
        { wch: 15 }, // Capacity
        { wch: 10 }, // Grade
        { wch: 15 }, // Lock Status
        { wch: 10 }, // Quantity
        { wch: 15 }, // Price
    ];

    worksheet['!cols'] = columnWidths;

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a unique filename for the Excel file
    const fileName = `data_${new Date().getTime()}.xlsx`;

    // Write the workbook to a buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the buffer
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a URL from the Blob
    const url = URL.createObjectURL(blob);

    // Create a link and click it to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    // Clean up by revoking the URL
    URL.revokeObjectURL(url);
};


